<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Tipo de Habitación - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
       
                <b-col md="6">
                  <b-form-group label="Nombre:">
                    <b-form-input
                      type="text"
                      ref="name"
                      v-model="type_room.name"
                      placeholder="Ingrese su nombre"
                    ></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger"
                      >Ingrese un nombre</small
                    >
                  </b-form-group>
                </b-col>

             
           
          
                <b-col md="6">
                  <b-form-group label="Cambiar Foto:">
                    <b-form-file
                      @change="onFileChange"
                      placeholder="Seleccione un foto..."
                      drop-placeholder="Suelta la imagen aquí..."
                    ></b-form-file>
                    </b-form-group>
                </b-col>
         
                <b-col md="10">
                  <b-form-group label="Descripción :" required>
                    <b-form-textarea
                      v-model="type_room.description"
                      rows="2"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      v-model="type_room.state"
                      class="form-control"
                    >
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>
         
               
       

            <b-col md="12">
                  <b-form-group label="Foto:" class="text-center">
                    <img class="img-fluid" :src="url_base + type_room.photo" alt="">
                    </b-form-group>
                </b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-button type="submit" class="form-control btn-primary"
                    >GUARDAR</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "CategoryEdit",
  props: ["id_type_room"],
  data() {
    return {
      module:'TypeRoom',
      type_room: {
        id_type_room: "",
        name: "",
        photo: "",
        photo_change: "",
        description: "",
        state: 1,
      },

      //errors
      errors: {
        id_type_room: false,
        name: false,
      },
      
      validate: false,
    };
  },
  mounted() {
    this.ViewTypeRoom();
  },
  methods: {
    ViewTypeRoom,
    EditTypeRoom,
    Validate,
    onFileChange
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

//ver usuario
function ViewTypeRoom() {
  let id_type_room = je.decrypt(this.id_type_room);
  let me = this;
  let url = this.url_base + "type-room/view/" + id_type_room;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.type_room.id_type_room = response.data.result.id_type_room;
        me.type_room.name = response.data.result.name;
        me.type_room.photo = response.data.result.photo;
        me.type_room.description = response.data.result.description;
        me.type_room.state = response.data.result.state;


      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {

    });
}

function onFileChange(e) {
  // e.preventDefault();
    this.type_room.photo_change = e.target.files[0];
    
}
//editar usuario
function EditTypeRoom(_this) {
 

  let me = _this;
  let url = me.url_base + "type-room/edit";

  let data = new FormData();
  data.append('id_type_room', me.type_room.id_type_room);
  data.append('name', me.type_room.name);
  data.append('photo', me.type_room.photo_change);
  data.append('description', me.type_room.description);
  data.append('state', me.type_room.state);

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      token: me.token,
      module: me.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.type_room.photo = response.data.result.photo;
        Swal.fire({ icon: 'success', text: 'Se ha modificado el tipo de habitación', timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {

    });
}

//validacion de formulario
function Validate() {

  this.errors.id_type_room = this.type_room.id_type_room.length == 0 ? true : false;
  this.errors.name = this.type_room.name.length == 0 ? true : false;
  this.errors.state = this.type_room.state.length == 0 ? true : false;


  if (this.errors.name) { this.validate = true; return false;}else{ this.validate = false; }

 let me = this;

    Swal.fire({
      title: 'Esta seguro de modificar los datos del tipo de habitación ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Estoy de Acuerdo!'
    }).then((result) => {
      if (result.isConfirmed) {
        EditTypeRoom(me);
      }
    })
}
</script>
